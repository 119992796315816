import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { patch } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  severityTransform,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const WebApplicationUpdateAsset = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  assetData,
  moduleID,
  getAssets,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [updateLoading, setUpdateLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const [address, set_address] = useState(assetData?.address);
  const [severity, set_severity] = useState();
  const [is_private, set_is_private] = useState(assetData?.is_private);
  const [is_internal, set_is_internal] = useState(assetData?.is_internal);
  const [is_credential, set_is_credential] = useState(assetData?.is_credential);

  useEffect(() => {
    let severityResult = severityTransform(assetData?.severity);
    set_severity(severityResult);
  }, [assetData]);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const updateAsset = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    let data = {
      module_id: moduleID,
      id: assetData.id,
      severity,
      address,
      is_credential,
      is_internal,
      is_private,
    };
    try {
      let result = await patch("/distributor/customer/module/asset", data);
      if (result?.success) toastSuccess(result?.message || "Asset Updated");
      getAssets();
      tog_standard();
      tog_standard5();
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  return (
    <div>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={updateAsset}
        text="Are you sure you want to update asset?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Update Asset
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="url">URL:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="email"
                pattern=".*"
                placeholder="URL"
                style={{ overflow: "hidden" }}
                defaultValue={assetData?.address}
                onChange={(e) => set_address(e.target.value)}
                disabled
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="is_credentials">Severity:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <select
                className="form-select"
                name="is_credentials"
                style={{ border: "1px solid #3fa0da" }}
                defaultValue={severityTransform(assetData?.severity)}
                onChange={(e) => set_severity(e.target.value)}
              >
                <option value="CRITICAL">CRITICAL</option>
                <option value="HIGH">HIGH</option>
                <option value="MEDIUM">MEDIUM</option>
                <option value="LOW">LOW</option>
              </select>
              <label id="is_credentials-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div className="d-flex justify-content-around">
            <div className=" ">
              <label className="form-label mb-1 ">Private</label>
              <div className="square-switch">
                <input
                  type="checkbox"
                  id="is_private_webapp"
                  name="is_private_webapp"
                  switch="primary"
                  defaultChecked={assetData?.is_private}
                  autoComplete="off"
                  onChange={(e) => set_is_private(e.target.checked)}
                />
                <label
                  htmlFor="is_private_webapp"
                  data-on-label="Yes"
                  data-off-label="No"
                />
              </div>
            </div>
            <div className=" ">
              <label className="form-label mb-1 ">Internal</label>
              <div className="square-switch">
                <input
                  type="checkbox"
                  id="is_internal_webapp"
                  name="is_internal_webapp"
                  switch="primary"
                  defaultChecked={assetData?.is_internal}
                  autoComplete="off"
                  onChange={(e) => set_is_internal(e.target.checked)}
                />
                <label
                  htmlFor="is_internal_webapp"
                  data-on-label="Yes"
                  data-off-label="No"
                />
              </div>
            </div>
            <div className=" ">
              <label className="form-label mb-1 ">Credential</label>
              <div className="square-switch">
                <input
                  type="checkbox"
                  id="is_credential_webapp"
                  name="is_credential_webapp"
                  switch="primary"
                  defaultChecked={assetData?.is_credential}
                  autoComplete="off"
                  onChange={(e) => set_is_credential(e.target.checked)}
                />
                <label
                  htmlFor="is_credential_webapp"
                  data-on-label="Yes"
                  data-off-label="No"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-success "
          >
            Update
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default WebApplicationUpdateAsset;
