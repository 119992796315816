import React, { useEffect, useState } from "react";
import { CardHeader, CardBody, Spinner } from "reactstrap";
import ProgressingTable from "./ProgressingTable";

const Progressing = ({ datas, userID, getTaskHistory }) => {
  const [entryValue, setEntryValue] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="special-card">
      <CardHeader>
        <h4 className="card-title text-primary ">Progressing Tasks</h4>
      </CardHeader>
      <CardBody>
        {isLoading && (
          <div className="d-flex justify-content-center  align-items-center h-100  ">
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              className="ms-2 "
              color="primary"
            />
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              overflowX: windowWidth < 1000 ? "scroll" : "none",
            }}
          >
            <ProgressingTable
              data={datas}
              entryValue={entryValue}
              changeValue={(e) => changeValue(e)}
              userID={userID}
              getTaskHistory={getTaskHistory}
            />
          </div>
        )}
      </CardBody>
    </div>
  );
};

export default Progressing;
