import React, { useEffect, useState } from "react";
import { get } from "../../../helpers/api_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, Spinner } from "reactstrap";
import Hosts from "../../../components/Customer/Assets/Hosts/Hosts";
import WebApplications from "../../../components/Customer/Assets/WebApplications/WebApplications";
import MailboxThreats from "../../../components/Customer/Assets/MailboxThreats/MailboxThreats";
import CyberWatch from "../../../components/Customer/Assets/CyberWatch/CyberWatch";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../common/InfoNoDataComponent";

const Assets = () => {
  document.title = "Assets | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [hosts, setHosts] = useState();
  const [webApps, setWebApps] = useState();
  const [cybers, setCybers] = useState();
  const [mailboxs, setMailboxs] = useState();

  const getCustomerModules = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module`);
      if (result.success) {
        for (const item of result.data.modules) {
          if (item.name === "HOST") {
            if ("permission" in item) {
              if (item.permission) {
                setHosts({ ...item });
              }
            } else {
              setHosts({ ...item });
            }
          }
          if (item.name === "WEB_APPLICATION") {
            if ("permission" in item) {
              if (item.permission) {
                setWebApps({ ...item });
              }
            } else {
              setWebApps({ ...item });
            }
          }
          if (item.name === "MXTHREAT") {
            if ("permission" in item) {
              if (item.permission) {
                setMailboxs({ ...item });
              }
            } else {
              setMailboxs({ ...item });
            }
          }
          if (item.name === "CYBER_WATCH") {
            if ("permission" in item) {
              if (item.permission) {
                setCybers({ ...item });
              }
            } else {
              setCybers({ ...item });
            }
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCustomerModules();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Assets" breadcrumbItem="Assets" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && (
            <>
              <div className="row">
                {webApps && (
                  <div className="col-xxl-6 mb-4 ">
                    <WebApplications webApps={webApps} />
                  </div>
                )}
                {mailboxs && (
                  <div className="col-xxl-6 mb-4 ">
                    <MailboxThreats mailboxs={mailboxs} />
                  </div>
                )}
                {hosts && (
                  <div className="col-xxl-6  mb-4   ">
                    <Hosts hosts={hosts} />
                  </div>
                )}
                {cybers && (
                  <div className="col-xxl-6 mb-4 ">
                    <CyberWatch cybers={cybers} />
                  </div>
                )}
              </div>
            </>
          )}
          {!isLoading && !cybers && !webApps && !hosts && !mailboxs && (
            <InfoNoDataComponent
              text="You don't have module."
              component="audits"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Assets;
