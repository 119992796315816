import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import emptyImage from "../../../../assets/images/empty.png";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import {
  calculateChange,
  removeBodyCss,
} from "../../../../common/utils/commonFunctions";
import { useSelector } from "react-redux";

const SeverityChanges = ({ data, default_widgets, defaultWidgetDelete }) => {
  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const [viewEdit, set_viewEdit] = useState(false);
  const [dataSeries, set_dataSeries] = useState([]);
  const [timeInterval, set_timeInterval] = useState("weekly");
  const [isZero, set_isZero] = useState(0);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  useEffect(() => {
    let result = calculateChange(data.weekly_overview, timeInterval);
    let dataSeries = [
      result["critical"],
      result["high"],
      result["medium"],
      result["low"],
      result["info"],
    ];
    set_dataSeries(dataSeries);
    let resultZero = areAllValuesZero(result);
    set_isZero(resultZero);
  }, [timeInterval]);

  const series = [
    {
      name: "Severities",
      data: dataSeries,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 10,
        columnWidth: "50%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        // return val + "%";
        return val + "";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: layoutMode === "dark" ? ["#c5c5c5"] : ["#000000"],
      },
    },
    colors: ["#FD625E", "#FD8C00", "#FDC500", "#2AB57D", "#4bA6EF"],
    grid: { show: true, borderColor: "#FFF" },
    xaxis: {
      categories: ["Critical", "High", "Medium", "Low", "Info"],
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
        fontSize: "100px",
        minHeight: 100,
        maxHeight: 180,
        style: {
          fontWeight: "bold",
          fontSize: "12px",
          color: "#304758",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          //   return val + "%";
          return val + "";
        },
      },
    },
    title: {
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
    legend: {
      show: false,
    },
  };
  const areAllValuesZero = (obj) => {
    return Object.values(obj).every((value) => value === 0);
  };

  const targetFunc = () => {
    defaultWidgetDelete("severity_changes");
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={targetFunc}
        text="Are you sure want to delete widget ?"
        loadingAction={false}
      />
      <div className="col-12 ">
        {default_widgets.severity_changes && (
          <div
            onMouseEnter={() => set_viewEdit(true)}
            onMouseLeave={() => set_viewEdit(false)}
            className="special-score-card "
          >
            <div className="d-flex flex-row position-relative  py-2  align-items-center justify-content-between  ">
              <div className="d-flex align-items-center justify-content-between">
                <span className="fw-bold fs-4 me-1 ">Severity Changes </span>{" "}
                <select
                  className="form-select me-5 "
                  style={{
                    padding: "2px",
                    width: "83px",
                  }}
                  onChange={(e) => set_timeInterval(e.target.value)}
                >
                  <option value="weekly">weekly</option>
                  <option value="monthly">monthly</option>
                  <option value="yearly">yearly</option>
                </select>
              </div>
              <div
                className={` cursor-pointer ${viewEdit ? "d-flex" : "d-none"}`}
                style={{ position: "absolute", top: 5, right: 0 }}
                onClick={tog_standard5}
              >
                <i className="mdi mdi-delete text-primary fs-3 " />
              </div>
            </div>
            {!isZero ? (
              <div
                style={{
                  height: "265px",
                }}
                className={`d-flex  align-items-center justify-content-center `}
              >
                <ReactApexChart
                  options={options}
                  series={series}
                  type="bar"
                  height="280"
                  style={{ marginTop: "45px" }}
                />
              </div>
            ) : (
              <div className="d-flex flex-column  align-items-center justify-content-center ">
                <img style={{ height: "232px" }} src={emptyImage} alt="empty" />
                <h4 className="text-center ">No change in severity values</h4>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SeverityChanges;
