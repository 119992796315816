import React, { useState } from "react";
import classnames from "classnames";
import {
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Modules from "./Sections/Modules";

const Sections = () => {
  const [activeTab1, setactiveTab1] = useState("1");

  const sectionList = [
    { id: "1", name: "Modules", icon: "mdi-view-module" },
    { id: "2", name: "Expand Asset", icon: "mdi-view-module" },
    { id: "3", name: "Licence Renewal", icon: "mdi-view-module" },
  ];

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  return (
    <div className="special-card">
      <Nav pills className="navtab-bg nav-justified gap-1">
        {sectionList.map((section) => (
          <NavItem key={section.id}>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames("border border-primary", {
                active: activeTab1 === section.id,
              })}
              onClick={() => {
                toggle1(section.id);
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <i className={`mdi ${section.icon} me-2 font-size-16`} />
                <span>{section.name}</span>
              </div>
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab1} className="pt-3 text-muted">
        <TabPane tabId="1">
          <Modules />
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
              <CardText className="mb-0">
                Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                single-origin coffee squid. Exercitation +1 labore velit, blog
                sartorial PBR leggings next level wes anderson artisan four loko
                farm-to-table craft beer twee. Qui photo booth letterpress,
                commodo enim craft beer mlkshk aliquip jean shorts ullamco ad
                vinyl cillum PBR. Homo nostrud organic, assumenda labore
                aesthetic magna delectus mollit. Keytar helvetica VHS salvia yr,
                vero magna velit sapiente labore stumptown. Vegan fanny pack
                odio cillum wes anderson 8-bit.
              </CardText>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
              <CardText className="mb-0">
                Etsy mixtape wayfarers, ethical wes anderson tofu before they
                sold out mcsweeney's organic lomo retro fanny pack lo-fi
                farm-to-table readymade. Messenger bag gentrify pitchfork
                tattooed craft beer, iphone skateboard locavore carles etsy
                salvia banksy hoodie helvetica. DIY synth PBR banksy irony.
                Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                mi whatever gluten-free, carles pitchfork biodiesel fixie etsy
                retro mlkshk vice blog. Scenester cred you probably haven't
                heard of them, vinyl craft beer blog stumptown. Pitchfork
                sustainable tofu synth chambray yr.
              </CardText>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Sections;
