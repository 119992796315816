import React from "react";

const Summary = () => {
  return (
    <div className="special-card">
      <div className="d-flex justify-content-center align-items-center  mb-3">
        <span className="fw-bold font-size-20 ">Summary</span>
      </div>
      <div className="row">
        <div className=" col-lg-12 form-floating mb-3">
          <input
            className="form-control"
            type="text"
            name="billTo"
            autoComplete="off"
          />
          <label>Bill to</label>
        </div>
        <div className=" col-lg-4 form-floating mb-3">
          <input
            className="form-control"
            type="text"
            name="city"
            autoComplete="off"
          />
          <label>City</label>
        </div>
        <div className=" col-lg-4 form-floating mb-3">
          <input
            className="form-control"
            type="text"
            name="address"
            autoComplete="off"
          />
          <label>Address</label>
        </div>
        <div className=" col-lg-4 form-floating mb-3">
          <input
            className="form-control"
            type="text"
            name="Post Code"
            autoComplete="off"
          />
          <label>Post Code</label>
        </div>
      </div>
      <hr className="my-3" />
      <div className="d-flex justify-content-center align-items-center">
        <span className="font-size-14 badge bg-primary fw-bold">
          Order Summary
        </span>
      </div>
      <table className="table table-borderless">
        <thead>
          <tr className="text-center align-middle">
            <th scope="col"></th>
            <th scope="col">Product</th>
            <th scope="col">Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center align-middle">
            <th scope="row">
              <span className="mdi mdi-arrow-right-thin text-primary fs-4" />
            </th>
            <td>Hosts</td>
            <td>$240</td>
          </tr>
          <tr className="text-center align-middle">
            <th scope="row">
              <span className="mdi mdi-arrow-right-thin text-primary fs-4" />
            </th>
            <td>Web Applications</td>
            <td>$540</td>
          </tr>
          <tr>
            <td colSpan="3">
              <hr className="my-3" />
            </td>
          </tr>

          <tr className="text-center align-middle">
            <th scope="row">Total</th>
            <td></td>
            <td>$780</td>
          </tr>
        </tbody>
      </table>
      <div className="d-flex justify-content-center align-items-center mb-3">
        <button
          type="button"
          className="btn btn-primary waves-effect btn-label waves-light w-50  "
        >
          <i className="mdi mdi-currency-usd me-3 label-icon"></i>
          BUY
        </button>
      </div>
    </div>
  );
};

export default Summary;
