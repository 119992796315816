import React from "react";
import Sections from "./Sections";
import Summary from "./Summary";

const PricingComponent = () => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-7">
          <Sections />
        </div>
        <div className="col-lg-5">
          <Summary />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PricingComponent;
