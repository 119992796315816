import React, { useEffect, useState } from "react";
import { Modal, CardHeader, Card, CardBody } from "reactstrap";
import WebApplicationTable from "./WebApplicationTable";
import WebApplicationTickets from "./WebApplicationTickets";
import { del, get, put, patch } from "../../../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const WebApplication = ({ datas }) => {
  const dispatch = useDispatch();

  const { errorFunction, errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [updateLoading, setUpdateLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [data, setData] = useState();
  const [entryValue, setEntryValue] = useState(10);
  const [viewTickets, setViewTickets] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  // Assets
  const [address, set_address] = useState("");
  const [severity, set_severity] = useState("CRITICAL");
  const [is_private, set_is_private] = useState(false);
  const [is_internal, set_is_internal] = useState(false);
  const [is_credential, set_is_credential] = useState(false);
  // Update
  const [is_active, set_is_active] = useState(datas.is_enable);
  const [asset_count, set_asset_count] = useState(datas.asset_count);

  const [tickets, setTickets] = useState();

  const changeValue = (value) => {
    setEntryValue(value);
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const tog_standard4 = () => {
    setmodal_standard4(!modal_standard4);
    removeBodyCss();
  };
  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const getAssets = async () => {
    paceFunction(true);
    try {
      let result = await get(
        `/manager/customer/module/asset?id=${datas?.user_id}&module_id=${datas?.id}`
      );
      if (result?.success) {
        setData(result?.data[0]?.assets);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  const getTickets = async () => {
    paceFunction(true);
    try {
      let result = await get(
        `/manager/customer/module/tickets?id=${datas?.id}`
      );
      if (result?.success) {
        setTickets(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getAssets();
    getTickets();
  }, []);

  const addRecord = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      address,
      severity,
      is_private,
      is_internal,
      is_credential,
      id: datas.id,
    };
    try {
      let result = await put("/manager/customer/module/asset", data);
      if (result?.success) {
        toastSuccess(result?.message || "Asset Added");
        getAssets();
        tog_standard();
        tog_standard5();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  const removeModule = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del(
        `/manager/customer/module?id=${datas?.user_id}&module_id=${datas?.id}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Module Removed");
        tog_standard4();
        dispatch({ type: "RENDER_COMPONENT_REQ" });
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  const updateWebApp = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    let data = {
      asset_count,
      is_enable: is_active,
      user_id: datas.user_id,
      id: datas.id,
    };
    try {
      let response = await patch("/manager/customer/module", data);
      if (response?.success) {
        toastSuccess(response?.message || "Module Updated");
        tog_standard3();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  return (
    <div className="col-xxl-12 mb-4  ">
      {/* Add Record */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addRecord}
        text="Are you sure you want to add record?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Update Module */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={updateWebApp}
        text="Are you sure you want to update module?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      {/* Remove Module */}
      <ConfirmModal
        modal_standard={modal_standard4}
        tog_standard={tog_standard4}
        setmodal_standard={setmodal_standard4}
        targetFunction={removeModule}
        text="Are you sure you want to remove module?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      {/* Add Record */}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Record
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="url">URL:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="email"
                pattern=".*"
                name="url"
                id="url"
                placeholder="URL"
                style={{ overflow: "hidden" }}
                onChange={(e) => set_address(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-is_credentials"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="is_credentials">Severity:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <select
                className="form-control"
                name="is_credentials"
                style={{ border: "1px solid #3fa0da" }}
                id="is_credentials"
                placeholder="Authorized Scan "
                onChange={(e) => set_severity(e.target.value)}
              >
                <option value="CRITICAL">CRITICAL</option>
                <option value="HIGH">HIGH</option>
                <option value="MEDIUM">MEDIUM</option>
                <option value="LOW">LOW</option>
              </select>
              <label id="is_credentials-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div className=" ">
            <label className="form-label mb-1 ">Private</label>
            <div className="square-switch">
              <input
                type="checkbox"
                id="is_private_webapp"
                name="is_private_webapp"
                switch="primary"
                defaultChecked={is_private}
                autoComplete="off"
                onChange={(e) => set_is_private(e.target.checked)}
              />
              <label
                htmlFor="is_private_webapp"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
          <div className=" ">
            <label className="form-label mb-1 ">Internal</label>
            <div className="square-switch">
              <input
                type="checkbox"
                id="is_internal_webapp"
                name="is_internal_webapp"
                switch="primary"
                defaultChecked={is_internal}
                autoComplete="off"
                onChange={(e) => set_is_internal(e.target.checked)}
              />
              <label
                htmlFor="is_internal_webapp"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
          <div className=" ">
            <label className="form-label mb-1 ">Credential</label>
            <div className="square-switch">
              <input
                type="checkbox"
                id="is_credential_webapp"
                name="is_credential_webapp"
                switch="primary"
                defaultChecked={is_credential}
                autoComplete="off"
                onChange={(e) => set_is_credential(e.target.checked)}
              />
              <label
                htmlFor="is_credential_webapp"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary "
          >
            Add Record
          </button>
        </div>
      </Modal>
      <Card className="border-primary special-card  ">
        <CardHeader className="d-flex justify-content-between   ">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-web me-3"></i>
            {datas?.text}
          </h5>
          <div className="d-flex ">
            <label className="form-label me-1 ">Active</label>
            <div className="square-switch">
              <input
                type="checkbox"
                id="is_active_webapp"
                name="is_active_webapp"
                switch="primary"
                defaultChecked={is_active}
                autoComplete="off"
                onChange={(e) => set_is_active(e.target.checked)}
              />
              <label
                htmlFor="is_active_webapp"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="dt-buttons btn-group flex-wrap">
            <button
              className="btn btn-primary"
              aria-controls="webapps_table"
              type="button"
              onClick={() => {
                tog_standard();
              }}
            >
              <span>Add</span>
            </button>
          </div>
          {data && (
            <div
              style={{
                overflowX: "scroll",
              }}
            >
              <WebApplicationTable
                data={data}
                entryValue={entryValue}
                changeValue={(e) => changeValue(e)}
                getAssets={getAssets}
                userID={datas.user_id}
                moduleID={datas.id}
              />
            </div>
          )}
          <div className="row mt-2 justify-content-center ">
            <div className="col-md-6">
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  name="numberof_asset"
                  type="number"
                  min={1}
                  step={1}
                  defaultValue={asset_count}
                  autoComplete="off"
                  onChange={(e) => set_asset_count(e.target.value)}
                />
                <label>Number Of Url Address</label>
              </div>
            </div>
          </div>
          <div className="col border-end d-flex justify-content-between align-items-end">
            <button
              onClick={() => {
                setViewTickets(!viewTickets);
              }}
              className="btn btn-outline-info "
            >
              <i className="mdi mdi-view-grid label-icon  me-1 " />
              {tickets && viewTickets ? "Close Tickets" : "View Tickets"}
            </button>
            <div className="d-flex flex-wrap justify-content-center  gap-2">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={tog_standard3}
              >
                <i className="bx bx-check-double label-icon" /> Update
              </button>
              <button
                className="btn btn-outline-danger removeModule"
                data-mod="WEB_APPLICATION"
                type="button"
                onClick={() => {
                  tog_standard4();
                }}
              >
                <i className="bx bx-block label-icon" /> Remove
              </button>
            </div>
            <span style={{ width: "122px" }}></span>
          </div>
          <AnimatePresence>
            {viewTickets && tickets && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 0, opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="mt-4"
              >
                <WebApplicationTickets tickets={tickets} />
              </motion.div>
            )}
          </AnimatePresence>
        </CardBody>
      </Card>
    </div>
  );
};

export default WebApplication;
