import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { CardHeader, Container } from "reactstrap";

const Example = () => {
  document.title = "Example Page";

  const canvasRef = useRef(null);
  const divRef = useRef(null);
  const [recordData, setRecordData] = useState();
  const [clientID, setClientID] = useState();
  const [wsConnection, set_wsConnection] = useState(false);
  const [isRecord, set_isRecord] = useState(false);
  // const [url, setUrl] = useState("");
  const [url, setUrl] = useState("http://testphp.vulnweb.com");
  // const [url, setUrl] = useState("https://test.platform.pentestbx.com/");
  const ws = useRef(null);

  useEffect(() => {
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (wsConnection) {
      if (ws.current && clientID) {
        ws.current.send(
          JSON.stringify({
            clientId: clientID,
            action: "id",
          })
        );
      }
    }
  }, [wsConnection]);

  const handleClick = (event) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      if (ws.current && ws.current.readyState === WebSocket.OPEN && isRecord) {
        ws.current.send(
          JSON.stringify({
            clientId: clientID,
            action: "click",
            x,
            y,
          })
        );
      }
    }
  };

  const handleKeyDown = (event) => {
    const keyData = {
      clientId: clientID,
      action: "keydown",
      key: event.key,
    };
    if (ws.current && ws.current.readyState === WebSocket.OPEN && isRecord) {
      ws.current.send(JSON.stringify(keyData));
    }
  };

  const handleWheel = (event) => {
    const canvas = canvasRef.current;

    if (canvas) {
      event.preventDefault();

      const deltaY = event.deltaY;

      if (ws.current && ws.current.readyState === WebSocket.OPEN && isRecord) {
        ws.current.send(
          JSON.stringify({
            clientId: clientID,
            action: "scroll",
            scrollPosition: { y: deltaY },
          })
        );
      }

      event.preventDefault();
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener("click", handleClick);
      canvas.addEventListener("wheel", handleWheel);
    }
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      if (canvas) {
        canvas.removeEventListener("click", handleClick);
        canvas.removeEventListener("wheel", handleWheel);
      }
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isRecord]);

  const startRecord = async () => {
    setRecordData();
    try {
      const canvas = canvasRef.current;
      canvas.width = window.innerWidth * 0.7;
      canvas.height = window.innerHeight;
      const response = await fetch(`${process.env.REACT_APP_LSRURL}/start`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientId: clientID,
          url,
          height: window.innerHeight,
          width: divRef.current.offsetWidth - 20,
        }),
      });
      if (response.ok) {
      }
    } catch (error) {
      console.error("Error sending URL:", error);
    }
    set_isRecord(true);
  };

  const stopRecord = async () => {
    if (wsConnection) {
      if (ws.current) {
        ws.current.close();
        set_wsConnection(false);
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
      }
    }
    set_isRecord(false);
    setRecordData();
  };

  const downloadSideFile = () => {
    const blob = new Blob([JSON.stringify(recordData, null, 2)], {
      type: "application/json",
    });

    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `${url}.side`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const startWsConnection = () => {
    ws.current = new WebSocket(process.env.REACT_APP_WSURL);

    ws.current.onopen = () => {
      console.log("WebSocket connected");
      set_wsConnection(true);
    };

    ws.current.onmessage = (event) => {
      let parsedEvent = JSON.parse(event.data);
      if (parsedEvent.action === "screenshot") {
        const img = new Image();
        img.src = `data:image/png;base64,${parsedEvent.img}`;
        img.onload = () => {
          const canvas = canvasRef.current;
          const ctx = canvas.getContext("2d");

          if (canvas.width === 0 || canvas.height === 0) {
            console.error("Canvas width or height is zero");
            return;
          }
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        };

        img.onerror = (error) => {
          console.error("Image failed to load:", error);
        };
      } else if (parsedEvent.action === "windowSize") {
        const canvas = canvasRef.current;
        if (canvas) {
          canvas.width = parsedEvent.width;
          canvas.height = parsedEvent.height - 140;
        }
      } else if (parsedEvent.action === "clientID") {
        setClientID(parsedEvent.clientId);
      } else if (parsedEvent.action === "recordedData") {
        setRecordData(parsedEvent.result);
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
      set_wsConnection(false);
    };
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="special-card">
            <CardHeader className="mb-3">
              <h4 className="card-title mb-0 text-primary ">
                Login Sequence Recorder{" "}
              </h4>
            </CardHeader>
            <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center mb-3">
                {wsConnection ? (
                  <i className="mdi mdi-lan-connect me-3 font-size-24  text-success" />
                ) : (
                  <i className="mdi mdi-lan-disconnect me-3 font-size-24 text-danger" />
                )}
                <button
                  disabled={wsConnection}
                  className={classNames("btn btn-info ", {
                    "cursor-no-drop": wsConnection,
                  })}
                  onClick={startWsConnection}
                >
                  Click to Connect to Server
                </button>
              </div>
              <div className="d-flex  justify-content-center align-items-center w-100">
                <button
                  disabled={!wsConnection || isRecord}
                  className={classNames("btn btn-primary me-4", {
                    "cursor-no-drop": !wsConnection || isRecord,
                  })}
                  onClick={startRecord}
                >
                  🔴 Start Record
                </button>
                <input
                  className={classNames(
                    "me-4 input-subject w-50 text-center ",
                    {
                      "bg-disabled cursor-no-drop": !wsConnection,
                    }
                  )}
                  defaultValue={url}
                  disabled={!wsConnection}
                  type="text"
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter your web application login url"
                />

                <button
                  disabled={!isRecord}
                  className={classNames("btn btn-danger ", {
                    "cursor-no-drop": !isRecord,
                  })}
                  onClick={stopRecord}
                >
                  ⭕ Stop Record
                </button>
              </div>
            </div>
            <div className="row">
              <div
                ref={divRef}
                className={classNames("col-xxl-9 col-xl-7", {
                  "border border-primary border-1 ": isRecord,
                })}
              >
                <canvas ref={canvasRef} />
              </div>
              {recordData && (
                <div className="col-xxl-3 col-xl-5 border border-primary border-1">
                  <h3 className="text-center my-5">Records</h3>
                  <div>
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Command</th>
                          <th scope="col">Target</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recordData.tests[0].commands.map((item, index) => (
                          <tr key={item.target + index}>
                            <th scope="row">{index + 1})</th>
                            <td>{item.command}</td>
                            <td>{item.target}</td>
                            <td>{item.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button
                      onClick={() => {
                        downloadSideFile();
                      }}
                      type="button"
                      className="btn btn-success waves-effect btn-label waves-light w-100 mt-2 "
                    >
                      <i className="mdi mdi-download label-icon"></i> Download
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Example;
