import React from "react";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import { Container } from "reactstrap";
import PricingComponent from "../../../../components/Customer/Account/Pricing/PricingComponent";

const Pricing = () => {
  document.title = "Pricing | PentestBX";
  const { errorFunction } = errorFunctionComponent();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pricing" breadcrumbItem="Pricing" />

          <PricingComponent />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pricing;
