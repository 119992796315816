import React from "react";

const Modules = () => {
  const cardList = [
    {
      name: "host",
      title: "Hosts",
      features: [
        "10 IP Adress",
        "10 scheduled monthly scan",
        "Internal and/or public ip",
        "For internal scans 'PentestBX Endpoint' required.",
        "Internal and/or public ip",
      ],
    },
    {
      name: "webApp",
      title: "Web Application",
      features: [
        "1 Domain Adress",
        "10 scheduled monthly scan",
        "Professionally curated checks",
      ],
    },
    {
      name: "mxThreats",
      title: "Mailbox Threats",
      features: [
        " 1 Mailbox Domain",
        "10 scheduled monthly scan",
        "Montly about 5000 Threats Mail Checks",
      ],
    },
    {
      name: "cyberWatch",
      title: "Cyber Watch",
      features: [
        "Reputations Details",
        "IP Blacklists Checks",
        "Subdomain Checks",
      ],
    },
  ];

  return (
    <div className="flex flex-wrap gap-2 justify-content-center">
      {cardList.map((card, index) => {
        return (
          <div
            key={card.name + index}
            style={{ width: "350px" }}
            className="flex flex-column justify-content-between border border-primary  p-3 rounded-2 "
          >
            <h3 className="mt-3 text-center">{card.title}</h3>
            <div className="mt-2 pt-2 text text-black">
              {card.features.map((item) => (
                <p key={item} className="mb-1 font-size-15">
                  <i className="mdi mdi-check-circle text-primary font-size-18 me-2" />
                  {item}
                </p>
              ))}
            </div>
            <div>
              <div className="mt-1 pt-1 d-flex justify-content-center">
                <div className="btn-group btn-group-sm" role="group">
                  <input
                    type="radio"
                    className="btn-check "
                    name={card.name}
                    id={card.name + 0 + index}
                    defaultChecked
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor={card.name + 0 + index}
                  >
                    1 Month
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name={card.name}
                    id={card.name + 1 + index}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary "
                    htmlFor={card.name + 1 + index}
                  >
                    3 Month
                  </label>
                  <input
                    type="radio"
                    className="btn-check"
                    name={card.name}
                    id={card.name + 2 + index}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor={card.name + 2 + index}
                  >
                    6 Month
                  </label>
                  <input
                    type="radio"
                    className="btn-check "
                    name={card.name}
                    id={card.name + 3 + index}
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor={card.name + 3 + index}
                  >
                    1 Year
                  </label>
                </div>
              </div>
              <h1 className="mt-2 text-center">
                <span id="system_modulespanPrice">$110.0</span>
                <span
                  className="text-muted font-size-16 fw-medium"
                  id="system_modulespanPlan"
                >
                  / 1 Month
                </span>
              </h1>
              <div className="mt-4 pt-2">
                <div
                  className="btn-group w-100"
                  role="group"
                  aria-label="Basic checkbox toggle button group"
                >
                  <input
                    type="checkbox"
                    className="btn-check choose_module"
                    id="system_module_choose"
                    autoComplete="off"
                  />
                  <label
                    className="btn btn-outline-primary w-100"
                    htmlFor="system_module_choose"
                  >
                    Choose Module
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Modules;
