import React, { useState } from "react";
import { Modal } from "reactstrap";
import { patch } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
  toastWarning,
} from "../../../../../common/utils/commonFunctions";

const MailboxThreatAssetUpdate = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  assetData,
  moduleID,
  getAssets,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [updateLoading, setUpdateLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const [address, set_address] = useState(assetData?.address);
  const [port, set_port] = useState(assetData?.port);
  const [username, set_username] = useState(assetData?.username);
  const [password, set_password] = useState("");
  const [protocol, set_protocol] = useState(assetData?.protocol);
  const [ssl, set_ssl] = useState(assetData?.is_ssl);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const updateAsset = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    let data = {
      module_id: moduleID,
      id: assetData.id,
      port,
      address,
      username,
      password,
      protocol,
      is_ssl: ssl,
    };
    if (password) {
      try {
        let result = await patch("/distributor/customer/module/asset", data);
        if (result?.success) toastSuccess(result?.message || "Asset Updated");
        getAssets();
        tog_standard();
        tog_standard5();
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      toastWarning("You should enter password");
    }
    setUpdateLoading(false);
    paceFunction(false);
    set_password("");
  };

  return (
    <div>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={updateAsset}
        text="Are you sure you want to update asset?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Update Asset
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-is_credentials"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="protocol">Protocol:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <select
                className="form-control"
                style={{ border: "1px solid #3fa0da" }}
                defaultValue={assetData?.protocol}
                onChange={(e) => set_protocol(e.target.value)}
              >
                <option value="IMAP">IMAP</option>
                <option value="POP3">POP3</option>
              </select>
              <label id="is_credentials-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="address">Mailbox Url:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control font-size-13"
                type="text"
                pattern=".*"
                placeholder="Mailbox Url imap.google.com (without schema)"
                style={{ overflow: "hidden" }}
                defaultValue={assetData?.address}
                onChange={(e) => set_address(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="port">Port:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="number"
                pattern=".*"
                min={1}
                max={65535}
                defaultValue={assetData?.port}
                style={{ overflow: "hidden" }}
                onChange={(e) => set_port(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="username">Username/E-mail:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="text"
                pattern=".*"
                placeholder="Username/E-mail"
                style={{ overflow: "hidden" }}
                defaultValue={assetData?.username}
                onChange={(e) => set_username(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="password">Password:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="text"
                pattern=".*"
                placeholder="Password"
                style={{ overflow: "hidden" }}
                onChange={(e) => {
                  set_password(e.target.value);
                }}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="ssl">SSL:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <div className="square-switch">
                <input
                  type="checkbox"
                  id="ssl"
                  name="ssl"
                  switch="primary"
                  defaultChecked={assetData?.is_ssl}
                  autoComplete="off"
                  onChange={(e) => set_ssl(e.target.checked)}
                />
                <label htmlFor="ssl" data-on-label="Yes" data-off-label="No" />
              </div>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-success "
          >
            Update
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MailboxThreatAssetUpdate;
