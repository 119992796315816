export const listModules = [
  "CYBER_WATCH",
  "HOST",
  "WEB_APPLICATION",
  "MXTHREAT",
  "NET_MON",
];

export const planLists = ["PROFESSIONAL", "PREMIUM", "ENTERPRISE", "CUSTOM"];

export const allModules = [
  { name: "NET_MON", text: "Network Topology" },
  { name: "CYBER_WATCH", text: "Cyber Watch" },
  { name: "WEB_APPLICATION", text: "Web Applications" },
  { name: "MXTHREAT", text: "Mailbox Threats" },
  { name: "HOST", text: "Hosts" },
];

export const dynamicWizardInfoType = [
  { label: "Last Scan Module Score", value: "last_module_score" },
  { label: "Last Scan Severity Count", value: "last_scan_severity_count" },
];

export const severityValues = [
  { label: "Critical", value: "critical" },
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
  { label: "Info", value: "info" },
];

export const moduleScoreValues = [
  { label: "Mailbox Threats", value: "Mailbox Threats" },
  { label: "Web Application", value: "Web Application" },
  { label: "Host", value: "Host" },
];

export const unsubscribeReasons = [
  "Insufficient Performance",
  "High Costs",
  "Product Incompatibility",
  "Lack of Support",
  "Changing Needs",
  "Competitive Alternatives",
  "Other",
];
