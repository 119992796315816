import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import HistoryComponent from "../../../../components/Customer/Account/History/HistoryComponent";
import { Container, Spinner } from "reactstrap";
import { get } from "../../../../helpers/api_helper";

const History = () => {
  document.title = "History | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [tasks, set_tasks] = useState();

  const getTaskHistory = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/task`);
      if (result.success) {
        set_tasks(result.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      setIsLoading(false);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getTaskHistory();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="History" breadcrumbItem="History" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && tasks && (
            <HistoryComponent tasks={tasks} getTaskHistory={getTaskHistory} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default History;
