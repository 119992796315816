import React from "react";
import Completed from "./Completed/Completed";
import Progressing from "./Progressing/Progressing";
import noHistoryImage from "../../../../assets/images/no-history.webp";

const HistoryComponent = ({ tasks, userID, getTaskHistory }) => {
  return (
    <div className="row gap-3 ">
      {tasks?.progressing?.length > 0 && (
        <div className="col-12">
          <Progressing
            datas={tasks?.progressing}
            getTaskHistory={getTaskHistory}
            userID={userID}
          />
        </div>
      )}
      {tasks?.completed?.length > 0 && (
        <div className="col-12">
          <Completed datas={tasks?.completed} />
        </div>
      )}
      {tasks?.completed?.length < 1 && tasks?.progressing?.length < 1 && (
        <div className="special-card d-flex flex-column justify-content-center align-items-center">
          <img src={noHistoryImage} alt="no-history" />
          <div className="fw-bold font-size-18">There is no task</div>
        </div>
      )}
    </div>
  );
};

export default HistoryComponent;
