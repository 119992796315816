import React, { useEffect, useState } from "react";
import { del, get } from "../../../helpers/api_helper";
import { CardHeader, CardBody, Spinner } from "reactstrap";
import AddMailboxThreats from "./AddMailboxThreats";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import MailboxThreatsUpdateAsset from "./MailboxThreatsUpdateAsset";

const MailboxThreats = ({ mailboxs }) => {
  const { errorToastFunction, errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [assets, set_assets] = useState();
  const [assetID, set_assetID] = useState();
  const [assetData, set_assetData] = useState();

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [addLoading, set_addLoading] = useState(false);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const tog_standard6 = () => {
    setmodal_standard6(!modal_standard6);
    removeBodyCss();
  };

  const getMailboxThreatsAsset = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/asset?id=${mailboxs?.id}`);
      if (result?.success) {
        set_assets(result?.data?.assets);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunctionNotSwal(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getMailboxThreatsAsset();
  }, []);

  const deleteAssets = async () => {
    paceFunction(true);
    set_addLoading(true);
    try {
      let result = await del(
        `/customer/module/asset?id=${mailboxs?.id}&asset_id=${assetID}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Assets Deleted");
        tog_standard6();
        getMailboxThreatsAsset();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <div className="special-card h-100">
      {/* Update Asset */}
      {assetData && (
        <MailboxThreatsUpdateAsset
          modal_standard={modal_standard5}
          tog_standard={tog_standard5}
          setmodal_standard={setmodal_standard5}
          assetData={assetData}
          moduleID={mailboxs?.id}
          getAssets={getMailboxThreatsAsset}
        />
      )}
      {/* Add Asset */}
      <AddMailboxThreats
        getMailboxThreatsAsset={getMailboxThreatsAsset}
        moduleID={mailboxs.id}
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={(e) => setmodal_standard(e)}
      />
      {/* Delete Asset */}
      <ConfirmModal
        modal_standard={modal_standard6}
        tog_standard={tog_standard6}
        setmodal_standard={setmodal_standard6}
        targetFunction={deleteAssets}
        text="Are you sure you want to delete asset?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between ">
          <h4 className="card-title text-primary ">Mailbox </h4>
          <button onClick={tog_standard} className="btn btn-primary ">
            Add Mailbox
          </button>
        </div>
      </CardHeader>
      {isLoading && (
        <div className="d-flex justify-content-center mt-4   align-items-center h-100  ">
          <Spinner
            style={{ width: "2rem", height: "2rem" }}
            className="ms-2 "
            color="primary"
          />
        </div>
      )}
      {!isLoading && assets && (
        <CardBody>
          <div style={{ maxHeight: "500px", overflowY: "overlay" }}>
            <table
              className="table align-middle datatable table-check"
              style={{ borderSpacing: "0 8px", width: "100%" }}
            >
              <thead>
                <tr className="bg-transparent">
                  <th>Domain Address</th>
                  <th>Port</th>
                  <th>Protocol</th>
                  <th>Username</th>
                  <th>Password</th>
                  <th>Action</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {assets?.map((item, index) => (
                  <tr key={index}>
                    <td className="fw-medium">{item?.address}</td>
                    <td className="fw-medium">{item?.port}</td>
                    <td className="fw-medium">{item?.protocol}</td>
                    <td className="fw-medium">{item?.username}</td>
                    <td className="fw-medium">{item?.password}</td>
                    <td>
                      <button
                        className="btn btn-success btn-sm"
                        type="button"
                        onClick={() => {
                          tog_standard5();
                          set_assetData(item);
                        }}
                      >
                        <span>Update</span>
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          set_assetID(item?.id);
                          tog_standard6();
                        }}
                        type="button"
                        className="btn btn-outline-danger btn-sm w-xs waves-effect btn-label waves-light border-danger border"
                      >
                        <i className="mdi mdi-tray-remove label-icon" /> Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      )}
    </div>
  );
};

export default MailboxThreats;
